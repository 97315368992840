<template>
  <div class="border-class pa-2 ma-3">
    <v-row no-gutters align="center">
      <v-col v-if="duplicatedKeys" cols="12" class="pb-2">
        <v-alert dense color="error" style="color: black">{{ $lang.errors.duplicatedKeys }}</v-alert>
      </v-col>
      <v-col cols="12" class="text-right pb-2">
        <v-btn
          icon
          :outlined="$vuetify.theme.dark"
          small
          class="color-secondary bg-outline-color"
          :disabled="!canEdit"
          @click="deconstructedData.push({ text: '', value: '' })"
        >
          +
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters align="center" class="pb-1">
          <v-col v-if="userSettings.display.showId" cols="1">
            <span>{{ $lang.header.id }}</span>
          </v-col>
          <v-col cols="4">
            <span>{{ $lang.header.variable }}</span>
          </v-col>
          <v-col cols="5" class="px-1">
            <span>{{ $lang.header.value }}</span>
          </v-col>
          <v-col cols="2" class="text-right">
            <span>{{ $lang.header.act }}</span>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-form
          ref="form"
          v-model="valid"
        >
          <template v-for="(item, i) in deconstructedData">
            <v-row :key="i" no-gutters align="center" class="pb-1">
              <v-col v-if="userSettings.display.showId" cols="1">
                <span>{{ i + 1 }}</span>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="item.text"
                  dense
                  :readonly="!canEdit"
                  required
                  :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
                  :data-cy="`template-key-${i}`"
                ></v-text-field>
              </v-col>
              <v-col cols="5" class="px-1 align-center">
                <v-text-field
                  v-model="item.value"
                  dense
                  required
                  :rules="[v => !!v || $lang.labels.required]"
                  :readonly="!canEdit"
                  :data-cy="`template-value-${i}`"
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="text-right">
                <div class="d-inline-flex">
                  <v-btn
                    icon
                    small
                    :disabled="!canEdit"
                    @click="editRow(i)"
                  >
                    <v-icon small color="info">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    class="ml-1"
                    icon
                    small
                    :disabled="!canEdit"
                    @click="deleteRow(i)"
                  >
                    <v-icon small color="error">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </template>
        </v-form>
      </v-col>
    </v-row>
    <v-dialog v-if="showEditor" v-model="showEditor" max-width="71%">
      <global-editor
        :title="$lang.labels.value"
        :item="selectedItem"
        :with-buttons="true"
        @closeDialog="showEditor = false; selectedItem = null"
        @fromGlobalEditor="updateFromEditor($event)"
      ></global-editor>
    </v-dialog>
  </div>
</template>

<script>
import globalEditor from '../../components/ui/GlobalEditor'
import { mapState } from 'vuex'
export default {
  components: {
    globalEditor
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return null
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data: () => ({
    valid: false,
    showEditor: false,
    selectedItem: null,
    selectedIndex: null,
    deconstructedData: [],
    selectedKeys: [],
    duplicatedKeys: false
  }),
  computed: {
    ...mapState('app', ['userSettings']),
    isFormValid() {
      return this.valid
    }
  },
  watch: {
    isFormValid: {
      handler() {
        this.$emit('formValid', this.isFormValid)
      },
      immediate: true
    },
    deconstructedData: {
      handler() {
        const tempData = {}

        this.deconstructedData.forEach((item) => {
          if (item.text && item.value) tempData[item.text] = item.value
        })

        this.selectedKeys = this.deconstructedData.map((x) => x.text)
        this.duplicatedKeys = !!this.hasDuplicates(this.selectedKeys)
        this.$emit('dataChanged', tempData)
      },
      deep: true
    }
  },
  created() {
    if (this.data) {
      for (const row in this.data) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.data.hasOwnProperty(row)) {
          this.deconstructedData.push({ text: row , value: this.data[row] })
        }
      }
    }
  },
  methods: {
    editRow(index) {
      this.selectedIndex = index
      this.selectedItem = this.deconstructedData[index].value
      this.showEditor = true
    },
    updateFromEditor(value) {
      this.deconstructedData[this.selectedIndex].value = value
    },
    hasDuplicates(arr) {
      return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x))
    },
    deleteRow(i) {
      this.deconstructedData.splice(i, 1)
    }
  }
}
</script>
<style lang="scss" scoped>
.border-class {
  border: 1px var(--v-customInputBorderColor-base) solid;
  border-radius: 6px;
}
</style>
